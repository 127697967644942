<template>
  <div class="rate_list_box padding-md text-white">
    <div v-if="$route.path.lastIndexOf('/') === 0">
      <!-- 搜索框 -->
      <div class="box-card">
        <div class="text item">
          <el-form inline class="padding-top margin-sm-bottom">
            <el-form-item label="角色编号">
              <el-input
                v-model="searchForm.id"
                style="width: 250px"
                clearable
              />
            </el-form-item>
            <!-- <el-form-item label="客户名称">
              <el-input
                v-model="searchForm.name"
                style="width: 250px"
                clearable
              />
            </el-form-item> -->
            <el-form-item>
              <el-button
                class="btn-purple"
                icon="el-icon-search"
                :disabled="role >= 1 ? true : false"
                @click="queryRole()"
                v-preventReClick
                >{{ $t("siteList.checkButton") }}</el-button
              >
              <el-button
                v-auth="btnAuth.add"
                class="btn-green"
                icon="el-icon-plus"
                @click="onAdd(1)"
                >{{ $t("deviceList.addEquipment") }}</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 站点数据信息展示表格 -->
      <div class="table-box">
        <!-- <div class="table-add">
          <el-button
            class="btn-blue"
            icon="el-icon-circle-plus"
            style="
              color: #8eb8f5;
              border: 0px;
              background: transparent;
              font-size: 15px;
            "
            @click="onAdd(1)"
            v-preventReClick
            >新增费率信息</el-button
          >
        </div> -->
        <el-table
          :data="UserData"
          style="width: 100%"
          :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }"
          :row-style="{ height: '60px' }"
          :row-class-name="tableRowClassName"
        >
          <el-table-column label="角色编号" prop="id" width="80">
          </el-table-column>
          <el-table-column label="角色名称" prop="name"> </el-table-column>
          <el-table-column label="角色类型" prop="type">
            <template slot-scope="scope">
              <span v-if="scope.row.type == 1">
                <span>管理员</span>
              </span>
              <span v-else-if="scope.row.type == 2"> 业主</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="createTime">
          </el-table-column>
          <el-table-column :label="$t('deviceList.operation')" width="150px">
            <template v-slot="{ row }">
              <div class="flex items-middle space-around">
                <!-- <el-button
                  class="text-red"
                  type="text"
                  @click="onDelete(row)"
                  icon="el-icon-delete"
                  v-auth="btnAuth.delete"
                  size="mini"
                  >{{ $t("deviceList.deleteDevice") }}</el-button
                >
                <el-button
                  class="text-blue flex"
                  type="text"
                  @click="onAdd(2, row)"
                  icon="el-icon-edit"
                  v-auth="btnAuth.edit"
                  size="mini"
                >
                  {{ $t("deviceList.editInfo") }}
                </el-button> -->
                <el-button
                  class="text-red"
                  type="text"
                  @click="onDelete(row)"
                  v-auth="btnAuth.delete"
                  size="mini"
                >
                  <icon type="icon-shanchu1-F" color="#234659" size="25"></icon
                ></el-button>
                <el-button
                  class="text-red"
                  type="text"
                  @click="onAdd(2, row)"
                  v-auth="btnAuth.edit"
                  size="mini"
                >
                  <icon
                    type="icon-wendang-caogao-F"
                    color="#234659"
                    size="25"
                  ></icon
                ></el-button>
                <!-- </div> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 翻页 -->
        <div class="block" style="margin-top: 15px">
          <pagination
            :total="countNum"
            v-on:getCurrent="changeCurrent"
            v-on:getPageSize="changePage"
          ></pagination>
        </div>
      </div>
    </div>
    <role-update ref="roleUpdate" @refresh="getRole"></role-update>
  </div>
</template>

<script>
import pagination from "../../components/pagination.vue";
import { roleQuery, roleDel } from "../../api/user";
// import DeviceInfo from "../../components/deviceInfo.vue";
import i18n from "@/lang";
import RoleUpdate from "./components/roleUpdate.vue";
// import CustomerUpdate from "@/components/customerUpdate.vue";
export default {
  components: { pagination, RoleUpdate },
  data() {
    return {
      searchForm: {
        id: "",
        name: "",
      },
      UserData: [],
      searchRules: {
        siteId: [
          { required: false, message: "请输入站点ID", trigger: "blur" },
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/,
            message: "用户ID以字母开头，且只能包含英文字母、数字、下划线",
          },
        ],
      },
      object: {
        pageNumber: 1,
        pageSize: 10,
      },
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页的数据条数
      role: 0, //角色
      countNum: 0,
      btnAuth: {
        add: "roleAdd",
        delete: "roleDelete",
        edit: "roleEdit",
      },
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getRole();
    },
    queryRole() {
      roleQuery({
        id: this.searchForm.id,
        para: { name: this.searchForm.name },
      })
        .then((res) => {
          console.log(res.data.data.list, "88888888");
          if (res.data.data.list.length == 0) {
            this.$message({
              type: "warning",
              message: "暂无结果",
            });
          } else {
            this.countNum = res.data.data.countNum;
            this.UserData = res.data.data.list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRole() {
      // console.log(obj);
      roleQuery(this.object)
        .then((res) => {
          console.log(res.data.data.list, "888888");
          this.countNum = res.data.data.countNum;
          this.UserData = res.data.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onAdd(type, row = {}) {
      this.$refs.roleUpdate.open(type, row);
    },
    onDelete(row) {
      console.log(row.id);
      this.$confirm(
        i18n.t("prompt.deletePromptInfo"),
        i18n.t("prompt.promptInfo"),
        {
          confirmButtonText: i18n.t("prompt.confirm"),
          cancelButtonText: i18n.t("prompt.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          roleDel({ id: row.id }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: i18n.t("prompt.operateSuccessfully"),
              });
              this.getRole();
            } else {
              this.$message({
                type: "error",
                message: i18n.t("prompt.operationFailure") + res.data.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: i18n.t("prompt.OperationCancelled"),
          });
        });
    },
    changeCurrent(data) {
      this.object.pageNumber = data;
      this.currentPage = data;
      console.log(this.object);
      this.getRole();
    },
    changePage(data) {
      this.object.pageSize = data;
      this.pageSize = data;
      console.log(this.object);
      this.getRole();
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "";
      } else {
        return "warning-row";
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.rate_list_box{
    width: 100%;
    box-sizing: border-box;
    height: calc(100vh - 130px);
    overflow: auto;
}
.table-box{
  padding: 20px;
    // background: linear-gradient(45deg, #35478a, #162257);
    background: #102135
    border-radius: 10px;
}
.table-add{
  margin-bottom: 20px
}
/deep/.el-table, .el-table tr, .el-table td, .el-table th {
    background-color: transparent!important;
}
</style>
