<template>
  <el-dialog
    :title="type === 1 ? '新增角色信息' : '修改角色信息'"
    :visible.sync="visible"
    width="20%"
    @close="close"
  >
    <el-form :model="userForm" ref="userForm" :rules="rules">
      <el-form-item prop="name" label="角色名称" label-width="80px">
        <el-input v-model="userForm.name"></el-input>
      </el-form-item>
      <el-form-item prop="type" label="角色类型" label-width="80px">
        <el-select v-model="userForm.type" placeholder="请选择">
          <el-option
            v-for="item in typeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="name" label="角色权限" label-width="80px">
        <el-tree
          :data="moduleList"
          :props="defaultProps"
          show-checkbox
          @check-change="checkChange"
          @node-click="handleNodeClick"
          node-key="id"
          ref="tree"
          :default-checked-keys="moduleKeys"
        >
        </el-tree>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-if="type != 3">
      <!-- <el-button @click="dialogVisible = false" type="info" size="mini"
            >返回</el-button
            > -->
      <el-button
        class="btn-blue"
        type="primary"
        style="color: #000"
        @click="onSubmit('userForm')"
        >{{
          type === 1 ? $t("addDevice.add") : $t("addDevice.edit")
        }}</el-button
      >
      <!-- <el-button type="primary" size="mini" v-else>修改</el-button> -->
    </span>
  </el-dialog>
</template>
<script>
import { roleAdd, roleEdit, moduleQuery } from "@/api/user.js";
import { deepClone } from "@/utils/deepClone";
import i18n from "@/lang";
export default {
  data() {
    return {
      visible: false,
      type: 1, //1新增 2编辑
      userForm: {},
      rules: {
        name: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
        password: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
        account: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
        customerId: [
          {
            required: true,
            message: "不为空",
            trigger: ["change"],
          },
        ],
        roleId: [
          {
            required: true,
            message: "不为空",
            trigger: ["change"],
          },
        ],
        type: [
          {
            required: true,
            message: "不为空",
            trigger: ["change"],
          },
        ],
      },
      customerOptions: [],
      roleOptions: [],
      typeOptions: [
        {
          id: 1,
          name: "管理员",
        },
        {
          id: 2,
          name: "业主",
        },
      ],
      moduleList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      moduleKeys: [],
    };
  },
  mounted() {},
  methods: {
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },
    handleNodeClick(data) {
      console.log(data);
    },
    getModule() {
      moduleQuery().then((res) => {
        console.log(res.data.data.list, "**********");
        this.moduleList = this.handleData(res.data.data.list);
        console.log(this.moduleList, "----------");
      });
    },
    checkChange(data, check) {
      console.log(data, "*/*///**", check, data.parentId);
      // debugger;
      // 父节点操作
      console.log(this.$refs, "jjjjjj");
      // 父节点操作
      if (data.parentId !== null) {
        if (check === true) {
          // 如果选中，设置父节点为选中
          this.$refs.tree.setChecked(data.parentId, true);
        } else {
          // 如果取消选中，检查父节点是否该取消选中（可能仍有子节点为选中状态）
          var parentNode = this.$refs.tree.getNode(data.parentId);
          var parentHasCheckedChild = false;
          console.log("parentNode", parentNode);
          for (
            var i = 0, parentChildLen = parentNode.childNodes.length;
            i < parentChildLen;
            i++
          ) {
            if (parentNode.childNodes[i].checked === true) {
              parentHasCheckedChild = true;
              break;
            }
          }
          if (!parentHasCheckedChild)
            this.$refs.tree.setChecked(data.parentId, false);
        }
      }
      // 子节点操作，如果取消选中，取消子节点选中
      if (data.children != null && check === false) {
        for (var j = 0, len = data.children.length; j < len; j++) {
          var childNode = this.$refs.tree.getNode(data.children[j].id);
          console.log(childNode, "-------");
          if (childNode.checked === true) {
            this.$refs.tree.setChecked(childNode.data.id, false);
          }
        }
      }
    },
    // getKeys() {
    //   var aKey = this.getCheckedKeys(
    //     this.rootData,
    //     this.$refs.rootTree.getCheckedKeys(),
    //     "value"
    //   );
    //   console.log(aKey);
    // },
    // getCheckedKeys(data, keys, key) {
    //   var res = [];
    //   recursion(data, false);
    //   return res;

    //   // arr -> 树形总数据
    //   // keys -> getCheckedKeys获取到的选中key值
    //   // isChild -> 用来判断是否是子节点
    //   function recursion(arr, isChild) {
    //     var aCheck = [];
    //     for (var i = 0; i < arr.length; i++) {
    //       var obj = arr[i];
    //       aCheck[i] = false;

    //       if (obj.children) {
    //         aCheck[i] = recursion(obj.children, true) ? true : aCheck[i];
    //         if (aCheck[i]) {
    //           res.push(obj[key]);
    //         }
    //       }

    //       for (var j = 0; j < keys.length; j++) {
    //         if (obj[key] == keys[j]) {
    //           aCheck[i] = true;
    //           if (res.indexOf(obj[key]) == -1) {
    //             res.push(obj[key]);
    //           }
    //           break;
    //         }
    //       }
    //     }
    //     if (isChild) {
    //       return aCheck.indexOf(true) != -1;
    //     }
    //   }
    // },
    handleData(data) {
      let obj = {},
        res = [],
        len = data.length;
      for (let i = 0; i < len; i++) {
        obj[data[i]["id"]] = data[i];
      }
      console.log(obj);
      for (let j = 0; j < len; j++) {
        let list = data[j];
        let parentList = obj[list["parentId"]];

        if (parentList) {
          if (!parentList["children"]) {
            parentList["children"] = [];
          }
          parentList["children"].push(list);
        } else {
          res.push(list);
        }
      }
      console.log(data, res.reverse(), "handleData");
      return res.reverse();
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.userForm);
          let obj = JSON.parse(JSON.stringify(this.userForm));
          const data = [
            ...this.$refs.tree.getHalfCheckedKeys(),
            ...this.$refs.tree.getCheckedKeys(),
          ];
          console.log("data", data);
          obj.moduleId = data.join(",");
          console.log(obj);
          if (this.type === 1) {
            this.addInfo(obj);
          } else if (this.type === 2) {
            this.editInfo(obj);
          }
        }
      });
    },
    addInfo(obj) {
      console.log("add", obj);
      roleAdd(obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: i18n.t("prompt.operateSuccessfully"),
          });
          this.$emit("refresh");
          this.visible = false;
        } else {
          this.$message({
            type: "error",
            message: i18n.t("prompt.operationFailure") + res.data.message,
          });
        }
      });
    },
    editInfo(obj) {
      console.log("edit", obj);
      roleEdit(obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: i18n.t("prompt.operateSuccessfully"),
          });
          this.$emit("refresh");
          this.visible = false;
        } else {
          this.$message({
            type: "error",
            message: i18n.t("prompt.operationFailure") + res.data.message,
          });
        }
      });
    },
    open(type, row) {
      console.log(typeof type, type);
      this.visible = true;
      this.getModule();
      this.type = type;
      if (type === 1) {
        this.viewDisable = false;
        this.userForm = {};
      } else if (this.type === 2) {
        this.userForm = deepClone(row);
        this.moduleKeys = this.userForm.moduleId.split(",");
        console.log(row, this.rateForm, "hhhhhh222", this.moduleKeys);
      } else if (type === 3) {
        this.userForm = deepClone(row);
      }
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
<style lang="stylus" scoped>
/deep/ .el-input{
  width 90% !important
}
/deep/ .el-select{
  width 100% !important
}
/deep/ .el-select .el-input{
  width 90% !important
}
/deep/ .el-tree{
  width 90% !important
}
</style>
